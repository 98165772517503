<template>
  <section class="content">
    <div class="card card-primary">
      <div class="card-body">
        <div class="row">
          <div class="col-auto ml-auto mb-2">
            <button class="btn btn-primary" @click.prevent="downloadKode">
              <i class="fa fa-download"></i> Download
            </button>
          </div>
        </div>
        <table class="table table-hover" ref="tabledest">
          <thead>
            <tr>
              <th>Propinsi</th>
              <th>Kota</th>
              <th>Kecamatan</th>
              <th>Kode Tujuan</th>
              <th>Kode Pos</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import $ from "jquery";

export default {
  name: "Destination",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      groups: [],
      formTitle: "Tambah Shipper",
      form: {
        name: "",
        username: "",
        email: "",
      },
    };
  },
  computed: {},
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
    //console.log('load initial data')
    authFetch("/users/groups")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.groups = js.data;
      });
  },
  methods: {
    downloadKode: function () {
      this.loadingDownload = true;
      authFetch("/master/destination/code_all", {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          this.loadingDownload = false;
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "KODE_TUJUAN.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
    },
  },
  mounted() {
    const e = this.$refs;
    var self = this;
    this.table = createTable(e.tabledest, {
      title: "Data Alamat",
      roles: ["read"],
      ajax: "/master/destination/all",
      frame: false,
			scrollX: true,
      autoWidth: false,
      lengthChange: false,
      filter: true,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      columns: [
        { data: "propinsi" },
        { data: "kota" },
        { data: "kecamatan" },
        { data: "kode" },
        { data: "kodepos" },
      ],
      filterBy: [0, 1, 2, 3, 4],
      rowCallback: function (row, data) {
        //$('td:eq(4)', row).html('<span class="label label-success">'+data.cnt+'</span>');
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah User";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit User";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          self.form = evt.data;
          authFetch("/users/" + evt.data.id, {
            method: "DELETE",
            body: "id=" + evt.data.id,
          })
            .then((res) => {
              return res.json();
            })
            .then((js) => {
              this.table.api().ajax.reload();
            });
        }
      },
      initComplete: function () {
        $('.loading-overlay').removeClass('show');
      },
    });
  },
};
</script>